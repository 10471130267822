<template>
  <OverviewActions
    type="subscriptions"
    :item-id="subscription.id"
    info
    edit
    remove
    :disable-remove="isSubscriptionPending"
    @onRemove="removeSubscription"
  />
</template>

<script>
import OverviewActions from '@/components/UI/OverviewActions'

export default {
  name: 'SubscriptionRowActions',
  components: {
    OverviewActions,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    pendingSubscriptions: {
      type: Array,
      default: _ => [],
    },
  },
  computed: {
    isSubscriptionPending() {
      if (this.pendingSubscriptions.indexOf(this.subscription.id) !== -1) {
        return true
      }
      return false
    },
  },
  methods: {
    removeSubscription() {
      this.$emit('onRemove')
    },
  },
}
</script>

<style lang="scss" scoped></style>
